import { createSelector } from 'reselect';
import { isSwagBundlesCreateGiveawaySelector, isSwagBundlesFlowFeatureEnabledSelector, isSwagBundlesInventoryTransferSelector, isSwagBundlesOrderMoreSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { getSwagBundlesStepValidation } from 'swag-common/business-logic/swag-bundles/validation/get-swag-bundles-validation';
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { SwagBundlesStep } from 'swag-common/constants/swag-bundles.constants';
import { isBoxType } from 'swag-common/utils/custom-store-product/product-is-box-type.util';
import { boxTypesSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { checkIsApparelInBundle, checkIsBundleAddon } from 'swag-client-common/utils/product.utils';
import { hasNotecardInBundle, hasPackagingInBundle, isBundle, isBundleAddon } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { canPullOnlyFromInventory } from 'swag-common/business-logic/inventory';
import { groupSwagBundleContentVariantsWithSizesByColor, swagBundleGroupContentByParentId } from '../../utils/swag-bundle-group-content';
import { isApprovalFlowEnabledSelector, isOrderMoreAvailableSelector, selectedFullInventorySelector } from '../inventory/inventory.selectors';
import { productSizeSettingsByIdSelector } from '../order-more/order-more.selectors';
import { isProductBundleByProductId, isProductInGiveawaySelector, productByProductIdSelector, productsSelector, selectedProductsSelector, singleProductSelector, singleProductSelectorByProductId } from '../products/products.selectors';
const isBundleFlowAvailableForCurrentTenant = window.swag.tenant === PREDEFINED_TENANTS.CUSTOM_INK;
export const swagBundlesSelector = state => state.swagBundles;
export const swagBundlesEditingSelector = createSelector(swagBundlesSelector, swagBundles => swagBundles.editedModel);
export const swagBundlesSelectedSelector = createSelector(swagBundlesSelector, swagBundles => swagBundles.selectedBundle);
export const isSwagBundlesFormValidSelectorByStepSelector = step => {
  const validator = getSwagBundlesStepValidation(step);
  return createSelector(swagBundlesEditingSelector, editing => validator({
    editing
  }));
};
const swagBundlesStepValidationMap = {
  [SwagBundlesStep.naming]: isSwagBundlesFormValidSelectorByStepSelector(SwagBundlesStep.naming)
};
export const isSwagBundlesFlowAvailableSelector = createSelector(isSwagBundlesFlowFeatureEnabledSelector, selectedFullInventorySelector, userIdSelector, isApprovalFlowEnabledSelector, (isBundleFlowEnabled, selectedInventory, userId, isApprovalEnabled) => {
  const canDistribute = selectedInventory && canPullOnlyFromInventory(selectedInventory, userId);
  return isBundleFlowEnabled && isBundleFlowAvailableForCurrentTenant && !(canDistribute && isApprovalEnabled);
});

// TODO: Update with reducer value when edit page is ready
export const isSwagBundlesEditPageLoaded = () => true;
export const swagBundlesStepSelector = createSelector(swagBundlesSelector, ({
  step
}) => step);
export const swagBundlesActiveStepSelector = createSelector(swagBundlesStepSelector, ({
  activeStep
}) => activeStep);
export const swagBundlesEditingNameSelector = createSelector(swagBundlesEditingSelector, editing => editing.name);
export const isSwagBundlesCurrentStepValidSelector = createSelector(state => state, swagBundlesActiveStepSelector, (rootState, activeStep) => {
  const selector = swagBundlesStepValidationMap[activeStep];
  if (!selector) {
    return false;
  }
  return selector(rootState);
});
export const areSwagBundlesStepButtonsDisabledSelector = createSelector(isSwagBundlesCurrentStepValidSelector, isValid => !isValid);
export const swagBundlesSelectedProductsAndAddonsSelector = createSelector(swagBundlesSelector, swagBundles => swagBundles.selectedProductsAndAddons);
export const swagBundlesAddOnsBuilderSelector = createSelector(swagBundlesSelector, swagBundles => swagBundles.addOnsBuilder);
export const swagBundlesPackageDataSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => packagingData);
export const swagBundlesSelectedProductsIdsSelector = createSelector(swagBundlesSelector, swagBundles => swagBundles.selectedProductsAndAddons.selectedProducts);
export const selectedProductsForSwagBundlesSelector = createSelector(productsSelector, swagBundlesSelectedProductsIdsSelector, (products, productsIds) => products.filter(({
  _id
}) => productsIds.includes(_id)));
export const swagBundlesPassedStepsSelector = createSelector(swagBundlesStepSelector, ({
  passedSteps
}) => passedSteps);
export const swagBundlesSkippedStepsSelector = createSelector(swagBundlesStepSelector, ({
  skippedSteps
}) => skippedSteps);
export const swagBundleSelectedBoxSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$select;
  return (_packagingData$select = packagingData === null || packagingData === void 0 ? void 0 : packagingData.selectedBox) !== null && _packagingData$select !== void 0 ? _packagingData$select : null;
});
export const swagBundleSelectedBoxColorSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$select2;
  return (_packagingData$select2 = packagingData === null || packagingData === void 0 ? void 0 : packagingData.selectedBoxColor) !== null && _packagingData$select2 !== void 0 ? _packagingData$select2 : null;
});
export const swagBundleSelectedBoxLogoSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$select3;
  return (_packagingData$select3 = packagingData === null || packagingData === void 0 ? void 0 : packagingData.selectedBoxLogo) !== null && _packagingData$select3 !== void 0 ? _packagingData$select3 : '';
});
export const swagBundleBoxNoteSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$boxNot;
  return (_packagingData$boxNot = packagingData === null || packagingData === void 0 ? void 0 : packagingData.boxNote) !== null && _packagingData$boxNot !== void 0 ? _packagingData$boxNot : '';
});
export const swagBundleSelectedCustomizationsSelector = createSelector(swagBundleSelectedBoxColorSelector, swagBundleSelectedBoxLogoSelector, swagBundleBoxNoteSelector, (selectedBoxColor, selectedBoxLogo, boxNote) => !!selectedBoxColor || !!selectedBoxLogo || !boxNote);
export const swagBundleAddOnsStepSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  step
}) => step);
export const swagBundleSelectedBoxCrinklePaperColorSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$boxCri;
  return (_packagingData$boxCri = packagingData === null || packagingData === void 0 ? void 0 : packagingData.boxCrinklePaperColor) !== null && _packagingData$boxCri !== void 0 ? _packagingData$boxCri : '';
});
export const swagBundleCustomBoxLogoLoadingSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$custom;
  return (_packagingData$custom = packagingData === null || packagingData === void 0 ? void 0 : packagingData.customBoxLogoLoading) !== null && _packagingData$custom !== void 0 ? _packagingData$custom : false;
});
export const swagBundleBrownBoxLogoLoadingSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  packagingData
}) => {
  var _packagingData$brownB;
  return (_packagingData$brownB = packagingData === null || packagingData === void 0 ? void 0 : packagingData.brownBoxLogoLoading) !== null && _packagingData$brownB !== void 0 ? _packagingData$brownB : false;
});
export const swagBundleIsPackagingSelectedToCreateSelector = createSelector(swagBundleSelectedBoxSelector, selectedBox => !!selectedBox && selectedBox !== SPECIAL_PRODUCT_TYPES.NO_BOX);
export const swagBundlesSelectedNotecardsSelector = createSelector(swagBundlesSelectedProductsAndAddonsSelector, ({
  selectedNotecards
}) => selectedNotecards);
export const swagBundlesSelectedPackagesSelector = createSelector(swagBundlesSelectedProductsAndAddonsSelector, ({
  selectedPackages
}) => selectedPackages);
export const swagBundlesSelectedProductsSelector = createSelector(swagBundlesSelectedProductsAndAddonsSelector, ({
  selectedProducts
}) => selectedProducts);
export const doesSwagBundlesHaveSomeProductsSelected = createSelector(swagBundlesSelectedProductsSelector, selectedProducts => selectedProducts.length > 0);
export const getSwagBundleContentByProductIdSelector = createSelector(productByProductIdSelector, product => (product === null || product === void 0 ? void 0 : product.variants[0].bundleContent) || []);
export const getSwagBundleContentGroupedByParentIdSelector = createSelector(getSwagBundleContentByProductIdSelector, bundleContent => swagBundleGroupContentByParentId(bundleContent));
export const swagBundleGetAllVariantsGroupedByParentIdWithColorsSelector = createSelector(getSwagBundleContentGroupedByParentIdSelector, groupedBundleContent => {
  return Object.entries(groupedBundleContent).reduce((result, [parentId, bundleContent]) => {
    result[parentId] = groupSwagBundleContentVariantsWithSizesByColor(bundleContent);
    return result;
  }, {});
});
export const swagBundleGetAllProductsWithGroupedVariantsByColorSelector = createSelector(swagBundleGetAllVariantsGroupedByParentIdWithColorsSelector, groupedBundleContent => {
  return Object.values(groupedBundleContent);
});
const getVariantSortingPriority = item => {
  if (checkIsApparelInBundle(item)) {
    return 1;
  } else if (isBundleAddon(item.swagProductId)) {
    return 3;
  }
  return 2;
};
export const swagBundleGetAllSortedProductsWithGroupedVariantsByColorSelector = createSelector(swagBundleGetAllProductsWithGroupedVariantsByColorSelector, variants => variants.flat().sort((prev, next) => {
  const prevPriority = getVariantSortingPriority(prev);
  const nextPriority = getVariantSortingPriority(next);
  return prevPriority - nextPriority;
}));
export const swagBundleVariantsByColorCountSelector = createSelector(swagBundleGetAllProductsWithGroupedVariantsByColorSelector, variants => Object.keys(variants).reduce((acc, parentId) => acc + variants[parentId].length, 0));
export const preSelectedNotecardProductSelector = createSelector(swagBundlesSelectedNotecardsSelector, state => state, (selectedNotecards, state) => {
  if (!selectedNotecards.length) {
    return null;
  }
  const product = productByProductIdSelector(state, {
    productId: String(selectedNotecards[0])
  });
  return product;
});
export const preSelectedPackagingProductSelector = createSelector(swagBundlesSelectedPackagesSelector, state => state, (selectedPackages, state) => {
  if (!selectedPackages.length) {
    return null;
  }
  const product = productByProductIdSelector(state, {
    productId: String(selectedPackages[0])
  });
  return product;
});
const preSelectedPackagingTypeSelector = createSelector(preSelectedPackagingProductSelector, selectedPackage => {
  var _selectedPackage$swag;
  return selectedPackage === null || selectedPackage === void 0 ? void 0 : (_selectedPackage$swag = selectedPackage.swagProductId) === null || _selectedPackage$swag === void 0 ? void 0 : _selectedPackage$swag.specialProductType;
});
export const preSelectedPackagingDataSelector = createSelector(boxTypesSelector, preSelectedPackagingTypeSelector, (boxes, selectedPackagingType) => boxes.find(box => box.type === selectedPackagingType));
export const swagBundleIsNotecardPreselectedSelector = createSelector(swagBundlesSelectedPackagesSelector, selectedPackages => Boolean(selectedPackages.length));
export const variantSelector = (_, {
  variant
}) => variant;
export const bundleVariantSizesOptionsWithTotalTransactionsSelector = createSelector(variantSelector, variant => {
  const {
    sizeSettings,
    sizeVariants
  } = variant;
  return sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options.map((sizeName, sizeIndex) => {
    const variantSize = sizeVariants === null || sizeVariants === void 0 ? void 0 : sizeVariants.find(v => v.sizeIndex === sizeIndex);
    const inStockOverall = ((variantSize === null || variantSize === void 0 ? void 0 : variantSize.inStockQuantity) || 0) - ((variantSize === null || variantSize === void 0 ? void 0 : variantSize.inReserveStockQuantity) || 0);
    const inProductionOverall = ((variantSize === null || variantSize === void 0 ? void 0 : variantSize.inProductionQuantity) || 0) - ((variantSize === null || variantSize === void 0 ? void 0 : variantSize.inReserveProductionQuantity) || 0);
    return {
      sizeName,
      inStockOverall,
      inProductionOverall
    };
  });
});
export const isApparelInBundleSelector = createSelector(variantSelector, variant => checkIsApparelInBundle(variant));
export const getAllApparelsInBundleSelector = createSelector(getSwagBundleContentByProductIdSelector, state => state, (bundleContent, state) => {
  return bundleContent.filter(variant => {
    if (!variant.product) {
      return false;
    }
    return isApparelInBundleSelector(state, {
      variant: variant.product
    });
  });
});
export const getAllNonApparelsInBundleSelector = createSelector(getSwagBundleContentByProductIdSelector, state => state, (bundleContent, state) => {
  return bundleContent.filter(variant => {
    if (!variant.product) {
      return false;
    }
    return !isApparelInBundleSelector(state, {
      variant: variant.product
    });
  });
});
export const getAllApparelsSpecificSizeInBundleSelector = sizeName => createSelector(getAllApparelsInBundleSelector, state => state, (bundleContent, state) => {
  return bundleContent.filter(variant => {
    var _variant$product, _variant$product2;
    const sizeSettings = variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.sizeSettings;
    if (!sizeSettings || sizeSettings.options.length <= 1) {
      return false;
    }
    let sizeIndex = -1;
    if (typeof sizeSettings === 'string') {
      var _foundSizeSettings$op;
      const foundSizeSettings = productSizeSettingsByIdSelector(sizeSettings)(state);
      sizeIndex = (_foundSizeSettings$op = foundSizeSettings === null || foundSizeSettings === void 0 ? void 0 : foundSizeSettings.options.findIndex(size => size === sizeName)) !== null && _foundSizeSettings$op !== void 0 ? _foundSizeSettings$op : -1;
    } else {
      sizeIndex = sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options.findIndex(size => size === sizeName);
    }
    return (variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.sizeIndex) === sizeIndex;
  });
});
export const isSwagBundlesInventoryTransferEnabledSelector = createSelector(isSwagBundlesInventoryTransferSelector, isBundlesInventoryTransferEnabled => {
  return isBundlesInventoryTransferEnabled && isBundleFlowAvailableForCurrentTenant;
});
export const isSwagBundlesCreateGiveawayEnabledSelector = createSelector(isSwagBundlesCreateGiveawaySelector, isSwagBundlesCreateGiveawayEnabled => {
  return isSwagBundlesCreateGiveawayEnabled && isBundleFlowAvailableForCurrentTenant;
});
export const isSwagBundlesOrderMoreEnabledSelector = createSelector(isSwagBundlesOrderMoreSelector, isSwagBundlesOrderMoreEnabled => {
  return isSwagBundlesOrderMoreEnabled && isBundleFlowAvailableForCurrentTenant;
});
export const isAtLeastOneBundleSelectedSelector = createSelector(selectedProductsSelector, selectedProducts => {
  const isAtLeastOneBundleSelected = selectedProducts.some(product => {
    var _product$variants;
    return isBundle(product === null || product === void 0 ? void 0 : (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : _product$variants[0]);
  });
  return isAtLeastOneBundleSelected;
});
export const productsWithBundleAddonsSelector = createSelector(selectedProductsSelector, selectedProducts => {
  return selectedProducts.filter(product => {
    const variants = product.variants || [];
    return checkIsBundleAddon(product, variants);
  });
});
export const isBundleAddonsSelectedSelector = createSelector(productsWithBundleAddonsSelector, productsWithAddons => !!productsWithAddons.length);
export const areApparelsInBundlesSelector = createSelector(getAllApparelsInBundleSelector, apparels => Boolean(apparels.length));
export const isBundleAddonSelector = createSelector(singleProductSelectorByProductId, product => checkIsBundleAddon(product, product.variants));
export const isBundleSelector = createSelector(singleProductSelectorByProductId, product => isBundle(product.variants[0]));
export const bundleSizesSelector = createSelector(getAllApparelsInBundleSelector, apparelsInBundle => {
  const variants = apparelsInBundle.map(variant => {
    var _variant$product3, _variant$product3$siz;
    return (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : (_variant$product3$siz = _variant$product3.sizeSettings) === null || _variant$product3$siz === void 0 ? void 0 : _variant$product3$siz.options;
  }).filter(sizesOptions => sizesOptions && sizesOptions.length > 1);
  return variants.reduce((acc, sizes) => {
    if (!sizes) {
      return acc;
    }
    acc = [...(acc || []), ...sizes.filter(size => variants.every(sizes => sizes && sizes.includes(size)))];
    return [...new Set(acc)];
  }, []);
});
export const isNotecardSelectedSelector = createSelector(swagBundlesSelectedNotecardsSelector, selectedNotecards => selectedNotecards.length > 0);
export const isPackagingSelectedSelector = createSelector(preSelectedPackagingDataSelector, packaging => !!packaging);
export const isBundlePackagingAndNotecardPreselectedSelector = createSelector(isPackagingSelectedSelector, isNotecardSelectedSelector, (hasPackaging, hasNotecard) => {
  return !!hasPackaging && !!hasNotecard;
});
export const getAllProductsInBundleWithoutAddonsSelector = createSelector(swagBundleGetAllProductsWithGroupedVariantsByColorSelector, variants => variants.flat().filter(variant => !isBundleAddon(variant.swagProductId)));
export const getAddonsInsideBundleSelector = createSelector(swagBundleGetAllProductsWithGroupedVariantsByColorSelector, variants => variants.flat().filter(variant => isBundleAddon(variant.swagProductId)));
export const isOrderMoreShownForSwagBundlesSelector = createSelector(isOrderMoreAvailableSelector, isProductBundleByProductId, isSwagBundlesOrderMoreEnabledSelector, isBundleAddonSelector, (isOrderMoreEnabled, isBundle, isBundleOrderMoreEnabled, isBundleAddon) => {
  return isOrderMoreEnabled && (isBundle ? isBundleOrderMoreEnabled : true) && !isBundleAddon;
});
export const hasNotecardInBundleSelector = createSelector(singleProductSelector, product => {
  var _product$variants$;
  return hasNotecardInBundle(product === null || product === void 0 ? void 0 : (_product$variants$ = product.variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$.bundleContent);
});
export const hasPackagingInBundleSelector = createSelector(singleProductSelector, product => {
  var _product$variants$2;
  return hasPackagingInBundle(product === null || product === void 0 ? void 0 : (_product$variants$2 = product.variants[0]) === null || _product$variants$2 === void 0 ? void 0 : _product$variants$2.bundleContent);
});
export const checkIsBundleInActivitiesSelector = createSelector(singleProductSelector, isProductInGiveawaySelector, (product, isProductInGiveaway) => {
  const bundle = product === null || product === void 0 ? void 0 : product.variants[0];
  const bundleContent = (bundle === null || bundle === void 0 ? void 0 : bundle.bundleContent) || [];
  const bundleHasReserved = bundleContent.some(variant => {
    const product = variant.product || {};
    const {
      inReserveBundleQuantity = 0,
      inReserveProductionBundleQuantity = 0,
      inReserveStockBundleQuantity = 0
    } = product;
    const inReserveBundleQuantityOverall = inReserveProductionBundleQuantity + inReserveStockBundleQuantity;
    return (inReserveBundleQuantity || inReserveBundleQuantityOverall) > 0;
  });
  return isProductInGiveaway || bundleHasReserved;
});
export const validCountOfAddonsForBundleTextSelector = createSelector(selectedProductsSelector, products => {
  const packages = products.filter(product => {
    const variants = product.variants || [];
    const variant = variants[0];
    return !isBundle(variant) && isBoxType(product.swagProductId);
  });
  const notecards = products.filter(product => {
    var _product$swagProductI;
    return ((_product$swagProductI = product.swagProductId) === null || _product$swagProductI === void 0 ? void 0 : _product$swagProductI.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
  });
  if (packages.length > 1 && notecards.length > 1) {
    return 'Only one notecard and one packaging allowed per box';
  } else if (packages.length > 1) {
    return 'Only one packaging allowed per box';
  } else if (notecards.length > 1) {
    return 'Only one notecard allowed per box';
  }
  return '';
});