import { createSelector } from 'reselect';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { FILTER_NAMES } from 'swag-common/constants/product.constants';
import { isCuratedBrandStoreSelector } from '../curated-brand-store/curated-brand-store-other.selector';
const boxAndInventoryFacetsSelector = createSelector(state => state.searchResults.facets, facets => facets.boxAndInventory || {});
const internationalShippingFacetsSelector = createSelector(state => state.searchResults.facets, facets => facets.internationalShipping || {});
export const boxAndInventorySelector = createSelector(boxAndInventoryFacetsSelector, isCuratedBrandStoreSelector, (boxAndInventoryFacets, isCuratedBrandStore) => {
  const filterData = boxAndInventoryFacets.global || [{
    name: FilterIds.isBox,
    id: FilterIds.isBox,
    value: false
  }, {
    name: FilterIds.isInventory,
    id: FilterIds.isInventory,
    value: false
  }, {
    name: FilterIds.isSuperSpeed,
    id: FilterIds.isSuperSpeed,
    value: false
  }];
  return {
    filterName: isCuratedBrandStore ? 'Box' : 'Box & Inventory',
    filterId: FilterIds.boxAndInventory,
    filterData
  };
});
export const shopCustomColorsSelector = () => ({
  secondaryColor: '',
  primaryColor: ''
});
export const internationalShippingSelector = createSelector(internationalShippingFacetsSelector, internationalShippingFacets => {
  const filterData = internationalShippingFacets.global || [{
    name: FilterIds.isInternationalShipping,
    id: FilterIds.isInternationalShipping,
    value: false
  }];
  return {
    filterName: 'Shipping',
    filterId: FILTER_NAMES.INTERATIONAL_SHIPPING,
    filterData
  };
});