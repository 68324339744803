function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import uuidv1 from 'uuid/v1';
import getUniqueSizeSet from 'swag-common/business-logic/products/get-unique-size-set.logic';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { Price } from 'swag-client-common/utils/price';
import { PRODUCTION_TIME_STANDARD_INDEX } from 'swag-common/constants/product.constants';
import { checkIsApparel } from 'swag-client-common/utils/product.utils';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { mapCartItemToRequiredFieldsForUpdate } from 'swag-common/utils/items/map-cart-item-to-required-fields.util';
import { getValidationMessages, ValidationPlaceMode } from 'swag-client-common/utils/validations';
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { productsSelector } from '../products/products.selectors';
import { colorsAmountSelector, embellishmentMethodSelector, initialCreationDateSelector, logosSelector, productListAvailableSizesSelector, productSkuSelector, selectedProductIdSelector, selectedSizeIndexSelector, sizeSelector, supplierInventoryLevelsSelector, supplierThresholdByIdSelector, swagProductSelector } from '../order-more/order-more.selectors';
import { productInStockLevelsByProductIdAndVariantSkuSelector } from '../order-more/order-more.selectors';
import { getOrderMoreItemSku, getBoxProducts, getProductKey, getStandaloneQuantity } from './order-more-box.utils';
export const rootSelector = state => state.orderMoreBox;
export const boxQuantitySelector = createSelector(rootSelector, state => state.boxQuantity);
export const boxItemsIdSelector = createSelector(rootSelector, state => state.boxItemsId);
export const isDistributedEvenlySelector = createSelector(rootSelector, state => state.isDistributedEvenly);
export const removedProductsFromBoxSelector = createSelector(rootSelector, state => state.removedProducts);
export const boxContentQuantitiesSelector = createSelector(rootSelector, state => state.contentQuantities);
export const boxContentQuantitiesPerBoxSelector = createSelector(rootSelector, state => state.contentQuantitiesPerBox);
export const selectedProductSelector = createSelector(productsSelector, selectedProductIdSelector, (products, selectedId) => products.find(({
  _id
}) => _id === selectedId));
export const boxProductsSelector = createSelector(selectedProductSelector, productListAvailableSizesSelector, (product, sizes) => {
  var _variants$, _swagProductId$produc;
  if (!product) {
    return [];
  }
  const {
    variants,
    swagProductId,
    reorderId,
    itemId
  } = product;
  const products = (_variants$ = variants[0]) !== null && _variants$ !== void 0 && _variants$.boxContent ? variants[0].boxContent.map(({
    product: {
      name,
      parentId,
      sizeIndex,
      defaultImage,
      swagProductId,
      colorVariantId,
      isCustomizedBlank,
      embellishmentMethod,
      initialCreationDate
    },
    quantity
  }) => {
    const swagProduct = swagProductId;
    const variant = swagProduct.variants.find(variant => variant.variantId === colorVariantId);
    const productSize = sizes.find(size => size._id === swagProduct.size);
    const boxProduct = _objectSpread({}, swagProduct, {
      name,
      itemId,
      variant,
      quantity,
      sizeIndex,
      reorderId,
      isCustomizedBlank,
      initialCreationDate,
      embellishmentMethod: embellishmentMethod || null,
      productSize,
      sizeId: productSize ? productSize._id : null,
      logo: defaultImage,
      customProductId: String(parentId)
    });
    if (reorderId) {
      boxProduct.reorderId = reorderId;
    }
    if (itemId) {
      boxProduct.itemId = itemId;
    }
    return boxProduct;
  }) : [];
  return swagProductId !== null && swagProductId !== void 0 && (_swagProductId$produc = swagProductId.products) !== null && _swagProductId$produc !== void 0 && _swagProductId$produc.length ? getBoxProducts(products, swagProductId) : products;
});
export const boxContentSelector = createSelector(selectedProductSelector, productListAvailableSizesSelector, (product, sizes) => {
  var _product$variants$;
  if (!product || !((_product$variants$ = product.variants[0]) !== null && _product$variants$ !== void 0 && _product$variants$.boxContent)) {
    return [];
  }
  return product.variants[0].boxContent.map(({
    quantity,
    product
  }) => {
    const productSize = sizes.find(size => {
      var _product$swagProductI;
      return size._id === ((_product$swagProductI = product.swagProductId) === null || _product$swagProductI === void 0 ? void 0 : _product$swagProductI.size);
    });
    return {
      quantity,
      product: _objectSpread({}, product, {
        swagProductId: _objectSpread({}, product.swagProductId, {
          productSize: productSize,
          sizeId: productSize === null || productSize === void 0 ? void 0 : productSize._id
        })
      })
    };
  });
});
export const boxItemsColorsSelector = createSelector(selectedProductSelector, product => {
  var _product$variants$2;
  if (!product || !((_product$variants$2 = product.variants[0]) !== null && _product$variants$2 !== void 0 && _product$variants$2.boxContent)) {
    return [];
  }
  return product.variants[0].boxContent.reduce((acc, customStoreBoxContent) => {
    const {
      product: {
        colorValue
      }
    } = customStoreBoxContent;
    const swagProduct = customStoreBoxContent.product.swagProductId;
    return _objectSpread({}, acc, {
      [swagProduct._id.toString()]: colorValue
    });
  }, {});
});
export const boxItemsContentSeparateQuantitiesSelector = createSelector(boxProductsSelector, boxQuantitySelector, boxContentQuantitiesSelector, boxContentQuantitiesPerBoxSelector, (contentProducts, boxQuantity, boxContentQuantities, boxContentQuantitiesPerBox) => {
  const separateProducts = {};
  const quantitiesKeys = Object.keys(boxContentQuantities);
  contentProducts.forEach((contentProduct, index) => {
    const {
      variant,
      _id
    } = contentProduct;
    const productKey = getProductKey((variant === null || variant === void 0 ? void 0 : variant.variantId) || String(_id), index);
    const colorsVariantsQuantities = {};
    const quantityPerBox = boxContentQuantitiesPerBox[productKey] || contentProduct.quantity || 1;
    if (!checkIsApparel(contentProduct)) {
      quantitiesKeys.forEach(key => {
        if (key === productKey) {
          colorsVariantsQuantities[key] = boxContentQuantities[key];
        }
      });
      Object.keys(colorsVariantsQuantities).forEach(key => {
        const quantities = colorsVariantsQuantities[key];
        const standaloneQuantity = getStandaloneQuantity(quantities, quantityPerBox, contentProduct, boxQuantity);
        if (standaloneQuantity > 0) {
          separateProducts[key] = standaloneQuantity;
        }
      });
    }
  });
  return separateProducts;
});
export const logosByProductSelector = createSelector(boxContentSelector, boxContent => boxContent.reduce((acc, content, position) => {
  return _objectSpread({}, acc, {
    [`${content.product.swagProductId._id}-${position}`]: content.product.logos
  });
}, {}));
export const boxSizeSetSelector = createSelector(boxProductsSelector, getUniqueSizeSet);
export const boxContentCustomProductsSelector = createSelector(selectedProductSelector, boxContentQuantitiesSelector, boxContentQuantitiesPerBoxSelector, boxItemsIdSelector, supplierThresholdByIdSelector, productListAvailableSizesSelector, (box, boxContentQuantities, boxContentQuantitiesPerBox, boxItemsId, supplierThresholdById, sizes) => {
  var _box$variants$;
  if (!box || !((_box$variants$ = box.variants[0]) !== null && _box$variants$ !== void 0 && _box$variants$.boxContent)) {
    return [];
  }
  return box.variants[0].boxContent.map(({
    quantity: initialQuantityPerBox,
    product
  }, index) => {
    const {
      colorVariantId
    } = product;
    const swagProduct = product.swagProductId;
    const colorVariant = swagProduct.variants.find(variant => variant.variantId === colorVariantId);
    const id = (colorVariant === null || colorVariant === void 0 ? void 0 : colorVariant.variantId) || swagProduct._id;
    const productKey = getProductKey(String(id), index);
    const quantities = boxContentQuantities[String(id)] || boxContentQuantities[productKey];
    const totalItemQuantity = quantities.reduce((total, cur) => total + cur, 0) || 0;
    const variant = swagProduct.variants.find(variant => (variant === null || variant === void 0 ? void 0 : variant.color) === product.colorValue);
    const quantityPerBox = boxContentQuantitiesPerBox[productKey] !== undefined ? boxContentQuantitiesPerBox[productKey] : initialQuantityPerBox;
    const productSize = sizes.find(size => size._id === swagProduct.size);
    return _objectSpread({}, product, {
      color: product.colorValue,
      _id: id,
      prodId: id,
      minQuant: swagProduct.minQuant,
      isReorder: true,
      isOrderMore: true,
      initialQuantityPerBox,
      quantityPerBox,
      quantity: totalItemQuantity,
      discount: false,
      boxItemsId,
      productSku: variant && variant.productSku,
      isAlwaysInStockVariant: variant && variant.isAlwaysInStockVariant,
      prod: _objectSpread({}, swagProduct, {
        quantity: totalItemQuantity,
        productSize,
        size: productSize,
        supplierOutOfStockThreshold: swagProduct.supplier ? supplierThresholdById[swagProduct.supplier] : null
      }),
      variants: [{
        quantities
      }]
    });
  });
});
export const quantityErrorSelectors = createSelector(selectedProductSelector, boxContentCustomProductsSelector, boxQuantitySelector, boxItemsIdSelector, removedProductsFromBoxSelector, productInStockLevelsByProductIdAndVariantSkuSelector, supplierInventoryLevelsSelector, (box, products, boxQuantity, boxItemsId, removedProductsFromBox, stockLevelsByProductIdAndVariantSku, supplierInventoryLevels) => {
  if (!box) {
    return {};
  }
  const boxes = [_objectSpread({}, box, {
    boxItemsId,
    prod: box.swagProductId,
    quantity: boxQuantity
  })];
  const validationMessages = getValidationMessages({
    boxes,
    stockLevelsByProductIdAndVariantSku,
    supplierInventoryLevels,
    isIndexAdded: true,
    ignoreThreshold: true,
    items: products,
    mode: ValidationPlaceMode.orderMore
  });
  const removedIds = Object.keys(removedProductsFromBox);
  for (const prodIdKey of Object.keys(validationMessages)) {
    if (removedIds.includes(prodIdKey)) {
      validationMessages[prodIdKey].isSuccessed = true;
    }
  }
  return validationMessages;
});
export const boxQuantityErrorSelector = createSelector(boxQuantitySelector, selectedProductSelector, (quantity, product) => {
  if (!product || !product.swagProductId) {
    return null;
  }
  const {
    swagProductId: {
      minQuant
    }
  } = product;
  return quantity < minQuant ? `Minimum quantity to order is ${minQuant}` : null;
});
export const boxMinQuantitySelector = createSelector(selectedProductSelector, product => {
  if (!product || !product.swagProductId) {
    return 0;
  }
  return product.swagProductId.minQuant;
});
export const isInputInvalidSelector = createSelector(quantityErrorSelectors, boxQuantityErrorSelector, (productsErrors, boxError) => {
  return Object.values(productsErrors).some(({
    isSuccessed
  }) => isSuccessed === false) || !!boxError;
});
const DEFAULT_MINIMUM_COLORS_NUMBER = 1;
const getProductForBox = (product, quantity, logosByProducts, position) => {
  const {
    sizeIndex,
    variants,
    _id,
    variant
  } = product;
  const logos = logosByProducts[`${_id}-${position}`] || {};
  const colors = isProductFullDesign(product) ? Object.values(logos).length ? DEFAULT_MINIMUM_COLORS_NUMBER : 0 : getColorsAmount(logos);
  const index = variants.findIndex(innerVariant => (innerVariant === null || innerVariant === void 0 ? void 0 : innerVariant.color) === (variant === null || variant === void 0 ? void 0 : variant.color));
  const variantInd = index !== -1 ? index : 0;
  const sizeInd = sizeIndex !== -1 ? sizeIndex : 0;
  const itemProductInBox = _objectSpread({}, product, {
    logos,
    colors,
    sizeInd,
    quantity,
    variantInd,
    prodId: _id
  });
  const priceForItem = Price.getPrice({
    orderItem: itemProductInBox,
    product
  });
  return _objectSpread({}, itemProductInBox, {
    priceForItem
  });
};
const getColorsAmount = logos => Object.keys(logos).reduce((colorsSum, location) => {
  const number = logos[location].reduce((colorsSumPerLocation, nextLogo) => nextLogo.colors.colorsNumber + colorsSumPerLocation, 0);
  return number + colorsSum;
}, 0);
const getQuantityForBoxItem = (orderQuantity, productInBox, box) => {
  const allQty = orderQuantity * productInBox.quantity;
  return allQty >= productInBox.minQuant && allQty >= (box.minQuant || 0) ? allQty : productInBox.minQuant;
};
const productMapperWithQuantities = (logosByProducts, generalBoxQuantity, boxProduct) => (product, position) => {
  const quantityPerBox = product.quantity;
  const quantity = getQuantityForBoxItem(generalBoxQuantity, product, boxProduct);
  const productForBox = getProductForBox(product, quantity, logosByProducts, position);
  return _objectSpread({}, productForBox, {
    quantityPerBox,
    initialCreationDate: product.initialCreationDate,
    customProductId: product.customProductId
  });
};
const getProductsFromBoxAbstractSelector = mappingProductFunction => createSelector(boxProductsSelector, logosByProductSelector, boxQuantitySelector, selectedProductSelector, (products, logosByProducts, generalBoxQuantity, boxProduct) => {
  if (!boxProduct || !products) {
    return [];
  }
  return products.map(mappingProductFunction(logosByProducts, generalBoxQuantity, boxProduct));
});
const getProductsWithQuantitiesFromBoxSelector = getProductsFromBoxAbstractSelector(productMapperWithQuantities);
export const boxContentItemsSelector = createSelector(getProductsWithQuantitiesFromBoxSelector, boxItemsIdSelector, boxContentQuantitiesSelector, boxContentQuantitiesPerBoxSelector, boxItemsColorsSelector, removedProductsFromBoxSelector, boxItemsContentSeparateQuantitiesSelector, (products, boxItemsId, contentQuantities, contentPerBoxQuantities, colors, removedProducts, boxItemsContentSeparateQuantities) => {
  const commonOrderItemFields = {
    boxItemsId,
    texts: {},
    textImages: [],
    discount: false,
    isReorder: true,
    blendedQuantity: undefined,
    customProductCreated: true,
    prodTime: PRODUCTION_TIME_STANDARD_INDEX
  };
  return products.reduce((all, product, position) => {
    const separateItems = [];
    const colorVariant = product.variants[product.variantInd];
    const productKey = getProductKey((colorVariant === null || colorVariant === void 0 ? void 0 : colorVariant.variantId) || product._id, position);
    const quantityPerBox = contentPerBoxQuantities[productKey] || product.quantityPerBox;
    if (removedProducts[productKey]) {
      return all;
    }
    const separateItemsQuantity = boxItemsContentSeparateQuantities[productKey] || 0;
    const contentQuantitiesByProduct = contentQuantities[getProductKey(colorVariant === null || colorVariant === void 0 ? void 0 : colorVariant.variantId, position)] || contentQuantities[getProductKey(product._id, position)];
    const quantities = contentQuantitiesByProduct.map(value => value > 0 ? value - separateItemsQuantity : value);
    const quantity = quantities.reduce((sum, value) => sum + value);
    const orderItem = _objectSpread({}, commonOrderItemFields, {
      quantity,
      quantityPerBox,
      prod: product,
      logos: product.logos,
      colors: product.colors,
      prodId: product.prodId,
      sizeInd: product.sizeIndex,
      variantInd: product.variantInd,
      itemSku: getOrderMoreItemSku(product),
      customProductId: product.customProductId,
      initialCreationDate: product.initialCreationDate,
      isCustomizedBlank: product.isCustomizedBlank,
      embellishmentMethod: product.embellishmentMethod || null,
      asRelatedItemId: uuidv1(),
      blendedQuantity: contentQuantitiesByProduct.reduce((sum, value) => sum + value),
      variants: [{
        quantities,
        variantIndex: product.variantInd
      }]
    });
    orderItem.price = Price.getBoxPrice({
      orderItem,
      product,
      isBoxItemsPriceAdded: false
    });
    orderItem.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount(orderItem);
    const transformedOrderItem = mapCartItemToRequiredFieldsForUpdate(orderItem);
    if (Number.isFinite(separateItemsQuantity) && separateItemsQuantity > 0) {
      const asRelatedItemId = uuidv1();
      const separateQuantities = quantities.map(value => value > 0 ? separateItemsQuantity : 0);
      transformedOrderItem.asRelatedItemId = asRelatedItemId;
      const separateItem = _objectSpread({}, transformedOrderItem, {
        quantities,
        boxItemsId: undefined,
        quantity: separateItemsQuantity,
        variants: [{
          variantIndex: product.variantInd,
          quantities: separateQuantities
        }]
      });
      separateItems.push(separateItem);
    }
    return [...all, transformedOrderItem, ...separateItems];
  }, []);
});
export const boxColorSelector = createSelector(selectedProductSelector, customProduct => {
  var _customProduct$varian, _customProduct$varian2;
  return (_customProduct$varian = customProduct === null || customProduct === void 0 ? void 0 : (_customProduct$varian2 = customProduct.variants[0]) === null || _customProduct$varian2 === void 0 ? void 0 : _customProduct$varian2.boxColor) !== null && _customProduct$varian !== void 0 ? _customProduct$varian : '';
});
export const boxVariantsToOrderSelector = createSelector(boxQuantitySelector, quantity => [{
  variantIndex: 1,
  quantities: [quantity]
}]);

// Solve problem about reselect limited number of selectors
export const itemPropsSelector = createSelector(swagProductSelector, selectedProductIdSelector, productSkuSelector, selectedSizeIndexSelector, logosSelector, colorsAmountSelector, embellishmentMethodSelector, sizeSelector, initialCreationDateSelector, (swagProduct, productId, itemSku, sizeInd, logos, colorsAmount, embellishmentMethod, size, initialCreationDate) => ({
  itemSku,
  initialCreationDate,
  colors: colorsAmount,
  prodId: swagProduct._id,
  sizeInd: sizeInd !== -1 ? sizeInd : 0,
  prod: _objectSpread({}, swagProduct, {
    size
  }),
  logos: logos || {},
  customProductId: productId,
  embellishmentMethod: embellishmentMethod ? String(embellishmentMethod) : null
}));
export const orderMoreBoxItemSelector = createSelector(swagProductSelector, itemPropsSelector, boxVariantsToOrderSelector, boxQuantitySelector, boxItemsIdSelector, removedProductsFromBoxSelector, boxColorSelector, (swagProduct, itemProps, variants, quantity, boxItemsId, removedProducts, boxColor) => {
  const isBoxChanged = Object.keys(removedProducts).length;
  const orderItem = _objectSpread({
    variants,
    boxItemsId,
    quantity,
    discount: false,
    prodTime: PRODUCTION_TIME_STANDARD_INDEX,
    isReorder: true,
    texts: {},
    textImages: [],
    isSample: false,
    isPrintedSample: false,
    blendedQuantity: quantity,
    customProductCreated: !isBoxChanged,
    price: 0,
    products: [],
    asRelatedItemId: uuidv1(),
    boxColor
  }, itemProps);
  orderItem.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount(orderItem);
  orderItem.price = Price.getPrice({
    orderItem: orderItem,
    product: swagProduct
  });
  if (swagProduct.variantDependentPrice && !isBox(swagProduct)) {
    orderItem.variantInd = variants[0].variantIndex;
  }
  return [orderItem];
});
export const orderMoreBoxItemsSelector = createSelector(orderMoreBoxItemSelector, boxContentItemsSelector, (orderItem, contentItems) => {
  return [...orderItem, ...contentItems];
});